var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"suit-container"},[(false)?_c('div',{staticClass:"suit-frame"},[_c('img',{staticClass:"suit-frame__image",attrs:{"src":_vm.data.types[_vm.selectedtie].file,"alt":"suit"}})]):_vm._e(),_c('h2',[_vm._v("Tie Types")]),_c('div',{staticClass:"suit-options"},[[_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('div',{staticClass:"check-container"},_vm._l((_vm.data.types),function(type,index){return _c('div',{key:type.id,class:{
                'custom-check': true,
                active: _vm.selectedtie == index,
                'empty-box': !type.part,
              },on:{"click":function($event){return _vm.updateTie(index)}}},[_c('div',{staticClass:"icon-container"},[(_vm.selectedtie == index)?_c('v-icon',{attrs:{"mdi-check":"","x-large":""}},[_vm._v("mdi-check")]):(type.price == 0)?_c('v-icon',{attrs:{"mdi-close":"","x-large":""}},[_vm._v("mdi-close")]):_vm._e()],1),_vm._v(" "+_vm._s(type.name)+" "+_vm._s(type.price !== 0 ? " (+ $" + _vm.data.types[index].price + ")" : "")+" ")])}),0)])]],2)]),_c('div',{staticClass:"tie-container"},[_c('h2',[_vm._v("Tie Colors")]),_c('div',{staticClass:"tie-colors"},_vm._l((_vm.data.colors),function(color,index){return _c('div',{key:color.id,class:{
          'tie-colors__single-colors': true,
          active:
            _vm.selectedcolor == index && _vm.data.types[_vm.selectedtie].price !== 0,
        },style:(("background-color: " + (color.hex) + "; border-color: " + (color.hex) + ";")),on:{"click":function($event){return _vm.updateColor(index)}}},[_c('div',{class:{ 'is-dark': _vm.isDark(color.rgb) }},[_vm._v(" "+_vm._s(color.name)+" ")])])}),0)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"quote"},[_c('i',[_vm._v("Swipe for color options")])])}]

export { render, staticRenderFns }