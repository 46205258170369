<template>
  <div>
    <v-container class="mb-6 pa-10 text-center" v-if="suitMasterData && suitCombo">
      <v-row align="start" no-gutters>
        <v-col cols="12" sm="12" md="12">
          <h2>Build My Suit</h2>
        </v-col>
        <v-col cols="12" sm="12" class="picker">
          <SuitPart :suits="suitMasterData.suits" title="Select Suit Color" :active="suitCombo.suit"
            @updateInfo="updateSuitCombo('suit', $event)" :totalPrice="
              fullSuit && fullSuit.priceNotShoe
                ? fullSuit.priceNotShoe.toFixed(2)
                : 0.0
            " />
          <hr />
        </v-col>
        <v-col cols="12" sm="12" class="picker">
          <SuitPart :suits="suitMasterData.shirts" title="Select Shirt color" :active="suitCombo.shirt"
            :nothumbnail="true" @updateInfo="updateSuitCombo('shirt', $event)" />
          <hr />
        </v-col>
        <v-col cols="12" sm="12" class="picker">
          <SuitTie :data="suitMasterData.ties" :selectedcolor="suitCombo.color || 0" :selectedtie="suitCombo.tie"
            @updateColor="updateSuitCombo('color', $event)" @tieUpdate="updateSuitCombo('tie', $event)" />
          <hr />
        </v-col>
        <v-col cols="12" sm="12" class="picker">
          <SuitPart :suits="suitMasterData.suitPackages" title="Shoes, Socks & Belt package"
            :active="suitCombo.suitPackage" :nothumbnail="true" @updateInfo="updateSuitCombo('suitPackage', $event)" />
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn depressed x-large @click="buildSuit()" class="groom-btn dark continue box-shadow">
            CONTINUE
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="loading" v-if="!!loading">
      <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
// email
import { Email } from "../vendor/smtp";

// Components
import SuitPart from "../components/SuitPart";
import SuitTie from "../components/SuitTie";

// mixin
import suitMixin from "../mixins/suitmixin";

// constants
import { SuitStatus } from "../constants/index";

import { api } from "@goldfishcode/modern-groom-api-sdk";
export default {
  mixins: [suitMixin],

  data: () => ({
    loading: false,
    userId: null,
    valid: false,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    phone: "",
    phoneRules: [(v) => !!v || "Phone is required"],
    date: "",
    dateRules: [(v) => !!v || "Date is required"],
  }),

  components: {
    SuitPart,
    SuitTie,
  },

  methods: {
    async buildSuit() {
      this.loading = true;
      const params = {
        suit_color: this.fullSuit.suit.id,
        shirt_color: this.fullSuit.shirt.id,
        tie_type: this.fullSuit.tie.id,
        ...(this.fullSuit.tie.price != 0
          ? { tie_color: this.fullSuit.color.id }
          : {}),
        more_item: this.fullSuit.suitPackage.id,
        price: this.fullSuit.price,
        suit_name: this.fullSuit.suit.id,
        status: SuitStatus.NEED_TO_PAY,
      };

      try {
        const result = await api.Suit.createSuits(params);
        this.userId = this.$route.query.userId;
        setTimeout(() => {
          this.loading = false;
          this.$router.push({
            path: `view-suit`,
            query: {
              suitId: result.id,
              ...(this.userId ? { userId: this.userId } : {}),
            },
          });
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
        alert(error.message);
      }
    },
    onChange(value) {
      this.fakeDropdownSelect = value;
    },
    updateSuitCombo(field, data) {
      this.suitCombo[field] = data;
    },
    sendMail() {
      this.validate();

      if (this.valid) {
        const token = process.env.VUE_APP_SMTP_TOKEN;

        const html = `<h2>Quote request</h2><div><h3>Clients data:</h3><p><b>Name: </b> ${this.name} <br> <b>Email: </b> ${this.email} <br> <b>State: </b> ${this.select} <br> <b>Phone: </b> ${this.phone} <br> <b>Phone: </b> ${this.date} </p> <h3>Suit data:</h3><p> Suit: </b> ${this.fullSuit.suit.name} <br> Shirt: </b> ${this.fullSuit.shirt.name} <br> Tie type: </b> ${this.fullSuit.tie.name} <br> Tie color: </b> ${this.fullSuit.color.name} <br> Suit build: </b> ${this.shareUrl} </p></div>`;

        Email.send({
          SecureToken: token,
          To: "info@themoderngroom.com, development@factor1studios.com, 2pdk8xbz@robot.zapier.com, info.themoderngroom@gmail.com",
          From: "no-reply@themoderngroom.com",
          Subject: "Suit quote request",
          Body: html,
        }).then((message) => {
          if (message == "OK") {
            alert("Message sent successfully");
            window.location.href =
              "https://themoderngroom.com/build-your-suit-thank-you";
          } else {
            alert(message);
          }
        });
      }
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },

  computed: {
    shareUrl() {
      let currentUrl = window.location.href;
      currentUrl =
        currentUrl.indexOf("?") < 0
          ? currentUrl
          : currentUrl.substring(0, currentUrl.indexOf("?"));
      currentUrl =
        currentUrl +
        `?suit=${this.suitCombo.suit}&shirt=${this.suitCombo.shirt}&tie=${this.suitCombo.tie}&color=${this.suitCombo.color}`;
      return currentUrl;
    },
  },
  async beforeMount() {
    try {
      localStorage.clear();
      this.loading = true;
      await this.getSuitMasterData();
      this.suitId = this.$route.query.suitId;
      if (this.suitId && this.suitMasterData) {
        await this.createSuitCombo();
      }
    } catch (error) {
      alert(error.message);
    } finally {
      const el = this;
      await new Promise(() => {
        setTimeout(function () {
          el.loading = false;
        }, 500);
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) !important;
}

.continue {
  font-family: "Roboto" !important;
  font-weight: 500;
  font-size: 16px !important;
}

hr {
  margin: 20px 0;
}

.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #00000038;
}

.picker {
  margin-left: 32%;
  max-width: 36%;
  flex: 0 0 36%;

  @media screen and (max-width: 959px) {
    margin: 0;
    max-width: 100%;
    flex: 0 0 100%;
  }
}
</style>
