<template>
  <div class="suit-container">
    <div class="suit-frame" v-if="!nothumbnail">
      <img class="suit-frame__image" :src="suits[active].file" alt="suit" />
      <p class="total-price">SUIT BASE PRICE: ${{ totalPrice }}</p>
    </div>
    <h2 v-if="title">{{ title }}</h2>
    <div class="suit-options">
      <div
        class="suit-options__option-container"
        v-for="(suit, index) in suits"
        :key="`suit-${suit.name}`"
        @click="updateInfo(index)"
      >
        <div
          v-if="suit.color"
          :class="{ 'suit-options__option': true, active: active == index }"
          :style="`background-color: ${suit.color}`"
        ></div>
        <div
          v-else
          :class="{ 'suit-options__option': true, active: active == index }"
          class="empty-box"
        >
          <v-icon mdi-window-close x-small>mdi-window-close </v-icon>
        </div>
        <span>
          {{ suit.name }}
          {{
            suits[index].price !== 0
              ? `${
                  suits[index].item_type !== SuitDataRes.SUITS ? "(+ $" : "($"
                }` +
                suits[index].price +
                ")"
              : ""
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { SuitDataRes } from "../constants/index";
import suitMixin from "../mixins/suitmixin";
export default {
  props: ["suits", "active", "title", "nothumbnail", "totalPrice"],

  mixins: [suitMixin],

  methods: {
    updateInfo(index) {
      this.$emit("updateInfo", index);
    },
  },
  data() {
    return {
      SuitDataRes,
    };
  },
};
</script>

<style lang="scss" scoped>
.suit-container {
  margin: 0 0;
  padding: 20px 0 10px;
}

.suit-frame {
  &__image {
    width: 100%;
  }
  .total-price {
    color: #22394d;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 10px 0 20px 0;
  }
}

.suit-options {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 30px;
  &__option-container {
    display: grid;
    grid-template-columns: 35px auto;
    grid-gap: 20px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
  }

  &__option {
    height: 35px;
    width: 35px;
    border: 4px solid #ffffff;
    border-radius: 10px;
    cursor: pointer;
    padding: 0 !important;
    &.active {
      border-color: #236096;
    }
  }
}
.empty-box {
  i {
    height: 100%;
    width: 100%;
  }
  &.empty-box {
    &.active {
      border: 4px solid #236096;
      i {
        border: none;
      }
    }
    i {
      height: 100%;
      width: 100%;
      border: 1px solid #dfdfdf;
      font-size: 17px !important;
      color: #dfdfdf;
      border-radius: 7px;
    }
  }
}
</style>
  
